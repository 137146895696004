.scrollTop {
    position: fixed; 
    right: 20px;
    width: auto;
    bottom: 20px;
    align-items: center;
    height: 20px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s ease-in-out;
    -webkit-animation: fadeIn 0.3s ease-in-out;
    -moz-animation: fadeIn 0.3s ease-in-out;
    -o-animation: fadeIn 0.3s ease-in-out;
    transition: opacity 0.4s ease-in-out;
    -webkit-transition: opacity 0.4s ease-in-out;
    -moz-transition: opacity 0.4s ease-in-out;
    -o-transition: opacity 0.4s ease-in-out;
    opacity: 0.5;
}

.scrollTop:hover{
    opacity: 1;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.5;
    }
}
