body {
  margin: 0;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(135deg, #ffffff 25%,  #398ae3 26% ,#398ae3 44%, #f41b89 45%, #f41b89 55%, #398ae3 56%, #398ae3 74%, #ffffff 75%) no-repeat fixed center;
  font-family: 'Fredoka One', cursive, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  max-width: 100%;
}