ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

li {
  float: left;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

li div, .dropbtn {
  display: inline-block;
  padding: 10px 12px;
  color: white;
  text-align: center;
  text-decoration: none;
  text-shadow: 2px 2px 10px black;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

li:hover, .dropdown:hover .dropbtn {
  background-color: #0000007a;
  cursor: pointer;
}

li.dropdown {
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #0000007a;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: white;
  padding: 10px 12px;
  text-decoration: none;
  text-shadow: 2px 2px 10px black;
  display: block;
  text-align: left;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.dropdown-content a:hover {
  background-color: #ffffff7a;
  cursor: pointer;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media only screen and (max-width: 524px) {
  .dropdown-content {
    right: 0;
  }
}