.warning-modal {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    background: linear-gradient(135deg, #ffffff 25%,  #398ae3 26% ,#398ae3 44%, #f41b89 45%, #f41b89 55%, #398ae3 56%, #398ae3 74%, #ffffff 75%) no-repeat fixed center;
}

.warning-title {
    width: 100%;
    color: white;
    font-family: 'Fredoka One', cursive;
    font-size: 3rem;
    text-align: center;
    text-shadow: 1px 1px 4px black;
    pointer-events: none;
}

#warning-subtext {
    font-size: 1rem;
    font-style: italic;
}

.warning-title {
    width: 80%;
    font-size: 1.5rem;
}

#warning-subtext {
    font-size: 0.75rem;
}

#warning-logo {
    max-width: 80vw;
    max-height: 50vh;
}

#warning-buttons-container {
    flex-direction: row;
}

#button-yes {
    color: white;
    background: red;
    transition: all .35s ease-in-out;
    -o-transition: all .35s ease-in-out;
    -moz-transition: all .35s ease-in-out;
    -webkit-transition: all .35s ease-in-out;
}

#button-yes:hover {
    background: white;
    color: red;
}

#button-no {
    color: white;
    background: #F41B89;
    transition: all .35s ease-in-out;
    -o-transition: all .35s ease-in-out;
    -moz-transition: all .35s ease-in-out;
    -webkit-transition: all .35s ease-in-out;
}

#button-no:hover {
    background: white;
    color: #F41B89;
}

@media only screen and (max-width: 1655px) {

}

@media only screen and (max-width: 1100px) {

}

@media only screen and (max-width: 960px) {

}

@media only screen and (max-width: 760px) {
    
}

@media only screen and (max-width: 484px) {

}