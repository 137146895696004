svg {
    display: block;
}

.socials-container {
    display: flex;
    justify-content: row;
    height: 30px;
}

.socials-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    padding: 5px;
    background: linear-gradient(-45deg, #398ae3, #f41b89);
    box-shadow: 2px 2px 10px black;
    border-radius: 7px;
}

.icon {
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s ease-in-out;
    -webkit-transition: transform 0.5s ease-in-out;
    -moz-transition: transform 0.5s ease-in-out;
    -o-transition: transform 0.5s ease-in-out;
}

.socials-icon:hover > .icon {
    transform: rotate(360deg);
}

.icon path {
    fill: white;
}
