.button {
  width: 150px;
  height: 50px;
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  text-decoration: none;
  font-family: 'Fredoka One', cursive;
  font-size: 1rem;
  text-shadow: 1px 1px 4px black;
  box-shadow: 2px 2px 10px black;
  cursor: pointer;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.App-logo {
  height: 50px;
}

.App-logo:hover {
  animation: wiggle 2.5s infinite;
  -webkit-animation: wiggle 2.5s infinite;
  -moz-animation: wiggle 2.5s infinite;
  -o-animation: wiggle 2.5s infinite;
  -ms-animation: wiggle 2.5s infinite;
}

/*
Sean's Colors
  BLUE = #398AE3
  PINK = #F41B89
*/

.App-header {
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  color: #ffffff;
}

.gradient-line-black { 
  height: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: black;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 5%,black 35%,black 65%, rgba(255, 255, 255, 0) 95%);
}

.gradient-line-white {
  display: none; 
  height: 6px;
  background: white;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 5%,white 35%,white 65%, rgba(255, 255, 255, 0) 95%);
}

.gradient-line-blue { 
  display: none;
  height: 4px;
  background:  #398AE3;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #398AE3 35%, #398AE3 65%, rgba(255, 255, 255, 0) 100%);
}

.gradient-line-pink { 
  display: none;
  height: 2px;
  background: #F41B89;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%,  #F41B89 35%,  #F41B89 65%, rgba(255, 255, 255, 0) 100%);
}

.App-link {
  color: #61dafb;
}

.flex-row-evenly {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.flex-row-center {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.two-row-col {
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.video-frame {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid black;
  animation: fadeIn ease-in-out 3s;
  -webkit-animation: fadeIn ease-in-out 3s;
  -moz-animation: fadeIn ease-in-out 3s;
  -o-animation: fadeIn ease-in-out 3s;
  -ms-animation: fadeIn ease-in-out 3s;
}

.social-link {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.socials-embeds {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.socials-embeds > * {
  margin: 10px;
}

.credits {
  width: 100%;
  margin-top: 20px;
  color: black;
  font-family: 'Fredoka One', cursive;
  font-size: .8rem;
  text-align: center;
  line-height: 1;
}

.credits > a {
  display: inline;
  color: black;
  font-size: 1rem;
  text-decoration: none;
  white-space: nowrap;
}

#twitch-embed {
  width: 940px;
  height: 529px;
}

#youtube {
  width: 350px;
  height: 197px;
}

#socials-large {
  flex-direction: row;
}

#socials-mini {
  flex-direction: row;
  flex-wrap: wrap;
  display: none;
}

.section-title {
  width: 100%;
  color: white;
  font-family: 'Fredoka One', cursive;
  font-size: 4rem;
  text-align: center;
  text-shadow: 2px 2px 10px black;
  pointer-events: none;
}

#about-image {
  width: 800px;
}

#about-text {
  display: inline;
  font-family: 'Fredoka One', cursive;
  font-size: 1.8rem;
  color: white;
  background-color: black;
  box-shadow: 0.1em 0 0 black, -0.1em 0 0 black;
  box-decoration-break: clone;
  line-height: 1.5;
}

#about-text-container {
  width: 800px;
}

@media only screen and (max-width: 1710px) {

  .main-embeds {
    margin-top: 1px;
  }

  .social-link {
    width: auto;
  }

  .social-link img {
    height: 35px;
  }

  .flex-row-evenly {
    flex-direction: column;
  }

  .two-row-col {
    justify-content: center;
  }

  #col1 {
    order: 2;
    flex-direction: row;
  }

  #col3 {
    order: 3;
    flex-direction: row;
  }
}

@media only screen and (max-width: 1100px) {
  #socials-large {
    display: none;
  }

  #socials-mini {
    display: flex;
  }
}

@media only screen and (max-width: 960px) {

  .App-header {
    height: 70px;
  }

  #twitch-embed {
    width: calc(100vw - 20px);
    height: calc((100vw - 20px) * 529 / 940)
  }

  .socials-embeds {
    flex-direction: column;
  }

  #about-text-container {
    width: calc(100vw - 20px);
  }

  #about-image {
    width: calc(100vw - 20px);
  }

}

@media only screen and (max-width: 760px) {

  .App-header {
    height: 100px;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .App-logo {
    height: 50px;
  }

  #col1 {
    flex-direction: column;
  }

  #col3 {
    flex-direction: column;
  }

  #socials-mini {
    display: none;
  }

  #youtube {
    height: calc((100vw - 20px) * 197 / 350);
    width: calc(100vw - 20px);
  }

  .video-frame {
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (max-width: 484px) {
  #about-text {
    font-size: 1rem;
  }
}

@media only screen and (max-height: 484px) {
  
}

@keyframes wiggle {
  from { transform: rotate(0deg); }
  25% { transform: rotate(2.5deg); }
  75% { transform: rotate(-2.5deg); }
  to { transform: rotate(0deg); }
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}